/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useNavigate } from "react-router-dom"
import { encryptStorage } from "../Utilities/common"
import Randomstring from 'randomstring'
import { logUserEvent, AuditEventType } from "../Utilities/common"
import Modal from "./Modal"
import { useState } from "react"

const Login = ({ login }) => {
  const navigate = useNavigate()
  const [isModalMOpen, setIsModalOpen] = useState(false)
 
  const loginAsGuest = () =>{
    const guestName = document.getElementById('ipGuestName').value.trim()
    if(guestName.length<3){
      setIsModalOpen(true)
      return
    }
    logUserEvent(AuditEventType.LoggedInAsGuest,{})
    encryptStorage.setItem('guest_name', guestName)
    const random = Randomstring.generate()
    encryptStorage.setItem('login_str', random)
    navigate(`/auth/linkedin/callback?state=${random}`)
  }


  return (<>
    <div className="lg:w-2/6 md:w-1/2 bg-gray-800 bg-opacity-50 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
      <br/>
      <input id='ipGuestName' className='p-2 mb-1 text-2xl rounded text-center' type="text" placeholder="Guest name"/> 
      <button className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-2xl" onClick={loginAsGuest}>Continue as guest</button> <br/>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <h2 className="text-white text-2xl font-medium title-font mb-5">Login with (for more features)</h2> 
      <img onClick={login} className="image-content cursor-pointer  lazy-load" alt="Image showing the new LinkedIn logo (English language)." srcSet="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Logo.svg.original.svg" />
      <p className="text-white text-xs mt-3"> * Only LinkedIn login supported as of now </p>
    </div>
    <Modal title={"Too short Name"}
                        type={"error"}
                        acceptButtonText={"Ok"}
                        visible={isModalMOpen}
                        body={"Your name must be at least 3 characters long!"}
                        mainAction={() => {
                          setIsModalOpen(false)
                        }}
                        cancelButtonText={'Cancel'}
                        cancelAction={() => { setIsModalOpen(false) }} />
  </>)
}

export default Login