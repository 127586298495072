/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useRef, useState } from 'react'
import { encryptStorage } from '../Utilities/common'
import './QuizWizard.css'
import { createEncryptedParams, getPublicQuizzes, downloadCurrentQuizContent, AuditEventType, logUserEvent } from '../Utilities/common'
import { decrypt } from "../Utilities/common"
import Modal from './Modal'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Timer from './Timer'



const QuizWizard = () => {
    const timerCommand = useSelector(state=>state.timer.command)
    const [quizTimeLimit, setQuizTimeLimit] = useState(-1)
    const [questions, setQuestions] = useState([])
    const [counter, setCounter] = useState(0)
    const userSelections = useRef({})
    // use the setIsModalOpen to open or close the modal
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [quizNotFound, setQuizNotFound] = useState(false)
    const [isQuizLeaveModalOpen, setQuizLeaveModalOpen] = useState(false)

    const [searchParams] = useSearchParams()
    const quizID = searchParams.get('id').trim()

    const navigate = useNavigate()
    const username = useSelector(state => state.auth.username)
    const indexContent = useSelector(state => state.index.content)
    const isPremium = username === 'Raviraj Parab'

    useEffect(()=>{
        const currQuiz = indexContent?.filter(i=>i.id===quizID)
        const qTimeLimit = currQuiz[0]?.timelimit
        if(qTimeLimit && qTimeLimit!==-1 & quizTimeLimit===-1){
            setQuizTimeLimit(qTimeLimit)
        }
    },[indexContent])

    // TODO
    let quizTitle = useRef(quizID)
    let [allowNext, setAllowNext ]= useState(true)
    // download the quiz content using the file ID
    const loadContentFromFile = async () => {
        try {
            const data = await downloadCurrentQuizContent()
            const info = JSON.parse(decrypt(`${JSON.stringify(data)}`))
            setQuestions(info.questions)
        } catch (error) {
            console.log('Could not download the quiz content')
        }
    }

    // clear the radios
    const clearOptions = () => {
        var ele = document.getElementsByName("quiz");
        for (var i = 0; i < ele.length; i++)
            ele[i].checked = false;
    }

    // listen to the timer command
    useEffect(()=>{
        if(timerCommand==='TimesUp'){
            calculateScore(timerCommand)
        }
    }, [timerCommand])

    useEffect(() => {
        const loadIndexAndThenTheQuizContents = async () => {
            const indexData = await getPublicQuizzes()
            // find the file ID from the indexData
            const quizFound = indexData.find(i => i.name === `${quizID}.json`);
            // find the contents of the quiz using the quizID
            if (quizFound) {
                // set the window 
                window.selectedQuiz = quizFound
                quizTitle.current = quizFound.name.split('.')[0]
                // load the contents of the quiz using the quizID
                await loadContentFromFile()
                logUserEvent(AuditEventType.QuizLoaded, { quizID })
            } else {
                setQuizNotFound(true)
            }
        }
        loadIndexAndThenTheQuizContents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // show next question
    const showNextQuestion = () => {
        if(isPremium){
            document.getElementById('lblCorrectAnswer').hidden=true
            document.getElementById('explanation').hidden=true
        }
      //  selectAutoSelectedOption()
        if (counter < questions.length - 1) {
            setCounter(c => c + 1)
        }

        // when all questions are finished
        if (counter === questions.length - 1) {
            // validate answers to see if user has forgotten to select any answer
            if (Object.keys(userSelections.current).length !== questions.length) {
                alert('You have missed answering some of the questions')
                // show the modal popup as you ahve missed answering some questions
            } else {
                // write logic to submit the answers
                // Are you sure you want to submit your answers
                setIsModalOpen(true)
            }
        }
        setAllowNext(true)
        clearOptions()
    }

    // const showPreviousQuestion = () => {
    //     if (counter > 0)
    //         setCounter(c => c - 1)
    // }

    const optionSelected = (e) => {
        userSelections.current[counter] = e.target.value
        // allow the next
        setAllowNext(false)
        if(isPremium){
            // show the correct answer
            document.getElementById('lblCorrectAnswer').hidden=false
            document.getElementById('explanation').hidden=false
        }
    }

    const highlightCorrectOption = () => {
        const previousSelection = userSelections.current[counter]
        if (previousSelection) {
            document.getElementsByName('quiz').forEach(i => {
                if (i.value === previousSelection) {
                    i.checked = true
                }
            })
        }
    }

    // const selectAutoSelectedOption = () => {
    //     const currentSelection = userSelections.current[counter]
    //     // incase user missed to select the radio button
    //     if (!currentSelection) {
    //         document.getElementsByName('quiz').forEach(i => {
    //             if (i.checked) {
    //                 userSelections.current[counter] = i.value
    //             }
    //         })
    //     }
    // }

    useEffect(() => {
        highlightCorrectOption()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter])

    useEffect(() => {
        // register some page functions to see if the user is leaving
        document.body.addEventListener("mouseleave", () => {
            setQuizLeaveModalOpen(true)
        })
    }, [])

    // main action calculate the marks
    const calculateScore = () => {
        setIsModalOpen(false)
        // calculate the score
        let correctAnswers = 0
        for (let index = 0; index < Object.keys(userSelections.current).length; index++) {
            const usersAnswers = userSelections.current[index]
            const actualAnswer = questions[index].options[questions[index].answer]
            if (usersAnswers === actualAnswer) {
                correctAnswers++
            }
        }
        // set the scores calculated flag
        const score = Math.round(correctAnswers * 100 / questions.length)
        // take to the results page
        const date = new Date().toLocaleDateString()
        // store the users score
        const _quizID = searchParams.get('id').trim()
        logUserEvent(AuditEventType.QuizCompleted, { username, quizID: _quizID, score })
        // get the proper title from the indexContent
        const currentQuiz = indexContent.filter(i => i.id === _quizID)[0]
        const guestUserName = encryptStorage.getItem('guest_name')
        navigate(`/results?d=${createEncryptedParams({
            score,
            quizTitle: currentQuiz?.title,
            quizID: _quizID,
            date,
            createdBy: currentQuiz?.createdBy,
            creatorsProfile: currentQuiz?.creatorsProfile,
            next: currentQuiz?.next,
            username : username==='Guest'? guestUserName : username
        })}`)
    }

    return (
        <>
            {questions.length === 0 && !quizNotFound && <div className="text-gray-100 bg-gray-900 body-font  md:col-span-3">
                <div className="mx-10 py-10">
                    <div class="flex gap-2 justify-center">
                        <div class="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
                        <div class="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
                        <div class="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
                    </div>
                    <div className='text-yellow-400 text-5xl text-center'>
                        Loading {quizTitle.current} quiz
                    </div>
                </div>
            </div>}

            {questions.length === 0 && quizNotFound && <div className="text-gray-100 bg-gray-900 body-font  md:col-span-3">
                <div className="mx-10 py-10">
                    <div className='text-red-400 text-5xl text-center'>
                        Quiz {quizTitle.current} doesn't exist!
                    </div>
                </div>
            </div>}

            {questions.length > 0 && <div className="text-gray-100 bg-gray-900 body-font  md:col-span-3">
                {/* <ADBanner height={50} width={320}/> */}
                <div className="mx-10 py-10 min-h-screen">
                    {<>
                        <p className="text-yellow-400 text-2xl">Q{counter + 1}/{questions.length}. {questions[counter].question}</p>
                        <br />
                        <input className='my-4' onClick={optionSelected} type="radio" id="a" name="quiz" value={questions[counter].options.a} />
                        <label className="px-3 text-xl" for="a">{questions[counter].options.a}</label><br />
                        <input className='my-4' onClick={optionSelected} type="radio" id="b" name="quiz" value={questions[counter].options.b} />
                        <label className="px-3 text-xl" for="b">{questions[counter].options.b}</label><br />
                        <input className='my-4' onClick={optionSelected} type="radio" id="c" name="quiz" value={questions[counter].options.c} />
                        <label className="px-3 text-xl" for="c">{questions[counter].options.c}</label> <br />
                        <input className='my-4' onClick={optionSelected} type="radio" id="d" name="quiz" value={questions[counter].options.d} />
                        <label className="px-3 text-xl" for="d">{questions[counter].options.d}</label> <br /> <br />
                        <button className='bg-blue-900 px-10 py-5 rounded float-right' disabled={allowNext} onClick={showNextQuestion}> {counter === questions.length - 1 ? 'Submit' : 'Next'} </button>
                        {isPremium && <label id='lblCorrectAnswer' hidden={true} className='text-3xl px-5 py-5 text-sky-500 border-dashed border-2 border-sky-500 '> Ans : {questions[counter].answer.toUpperCase()}</label> }
                        { quizTimeLimit!==-1 && <Timer quizID={quizID} timeLimitInMinutes={quizTimeLimit}/>}
                        {/* <button className={`${counter === 0 ? 'bg-blue-300' : 'bg-blue-900'} px-10 py-5 rounded float-left`} disabled={counter === 0} onClick={showPreviousQuestion}> Previous </button> */}
                        {isPremium && <div id='explanation' hidden={true} className='fixed inset-x-0 bottom-20 bg-gray-900 text-white text-sm p-3 border-dashed border-2 border-gray-500'> Explanation : {JSON.stringify(questions[counter].explanation)}</div> }
                    </>}
                    <Modal title={"Confirmation"}
                        type={"success"}
                        acceptButtonText={"Yes, Please submit"}
                        visible={isModalOpen}
                        body={"Once you submit the answers, you won't be able to change them. Are you sure you want to submit your answers?"}
                        mainAction={calculateScore}
                        cancelAction={() => { setIsModalOpen(false) }} />

                    <Modal title={"Quiz Incomplete"}
                        type={"warning"}
                        acceptButtonText={"Yes, I need time to prepare"}
                        visible={isQuizLeaveModalOpen}
                        body={"Are you not prepared to take the quiz?"}
                        mainAction={() => {
                            logUserEvent(AuditEventType.MovedOutOfQuiz, { username })
                            // ideally should be moved to quiz index
                            navigate('/')
                        }}
                        cancelButtonText={'No, I will continue with the quiz'}
                        cancelAction={() => { setQuizLeaveModalOpen(false) }} />
                </div>
            </div>}
        </>
    )
}

export default QuizWizard