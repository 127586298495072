import { loadScript } from "./common"
// This is the first this that must happen
export const initializeFB = async () =>{
    await loadScript('https://connect.facebook.net/en_US/sdk.js')
    await window.FB.init({
        appId            : '769955365114542',
        xfbml            : true,
        version          : 'v18.0'
      });
   return window.FB
}
export const getLoginStatus = ()=>new Promise((resolve, reject)=>{
    try {
        window.FB.getLoginStatus(function(response) {
            resolve(response)
        });
    } catch (error) {
        reject(error.message)
    }
})
export const login = () => new Promise((resolve, reject) => {
    try {
        window.FB.login(function (response) {
            if (response.authResponse) {
                window.FB.api('/me', { fields: 'name, email' }, function (response) {
                    // {name: 'Raviraj Parab', id: '3733316396956103'}
                    console.log(response)
                    resolve(response)
                });
            } else {
                reject('User cancelled login or did not fully authorize.');
            }
        });
    } catch (error) {
        reject(error.message);
    }
})
export const logout = () => new Promise((resolve,reject)=>{
    try {
        window.FB.logout(function(response) {
            if('status' in response){
                resolve(response.status)
            }
         });
    } catch (error) {
        reject(error.message);   
    }
})