const Modal =({type, title, body, acceptButtonText, visible, cancelAction, mainAction, cancelButtonText='Cancel'}) =>{
    // the let values will change based on the type of modal
     //const type = 'warning' // error and warning
    //const acceptButtonText = 'Done' // Ok, Continue, Accept, Retry, ... Please wait, Done
    // const title = 'Modal Title Message'
    // const body = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem mollitia inventore quod. Yay!'

    let svgIconClasses = ''
    let svgStrokeD = ''
    let strokeBg = ''
    let acceptButtonBg = ''
    let acceptButtonBgHover = ''
    let acceptButtonFocusOutline = ''

    if(type==='success'){
        svgIconClasses = "h-6 w-6 text-green-500" 
        svgStrokeD = "M5 13l4 4L19 7"
        strokeBg = "green-400"
        acceptButtonBg = "bg-green-600"
        acceptButtonBgHover = "bg-green-800"
        acceptButtonFocusOutline ="shadow-outline-green"
    }
    else if(type==='error'){
        svgIconClasses = "h-6 w-6 text-red-500" 
        svgStrokeD = "M6 18L18 6M6 6l12 12"
        strokeBg = "red-100"
        acceptButtonBg = "bg-red-600"
        acceptButtonBgHover = "bg-red-900"
        acceptButtonFocusOutline ="shadow-outline-red"
    }
    else{
        svgIconClasses = "h-6 w-6 text-orange-300" 
        svgStrokeD = "M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        strokeBg = "orange-100"
        acceptButtonBg = "bg-orange-400"
        acceptButtonBgHover = "bg-orange-600"
        acceptButtonFocusOutline ="shadow-outline-orange"
    }
    
    if(!visible){
        return <></>
    }
    
    return (<div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
                <div
                    class={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${strokeBg} sm:mx-0 sm:h-10 sm:w-10`}>
                    <svg class={svgIconClasses} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                         <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d={svgStrokeD}></path> 
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        {title}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            {body}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button type="button" onClick={mainAction}
                        class={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 ${acceptButtonBg} text-base leading-6 font-medium text-white shadow-sm hover:${acceptButtonBgHover} focus:outline-none focus:${acceptButtonFocusOutline} transition ease-in-out duration-150 sm:text-sm sm:leading-5`}>
                        {acceptButtonText}
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button type="button" onClick={cancelAction}
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        {cancelButtonText}
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>)
}

export default Modal