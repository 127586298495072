/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from "react-router-dom"
import { encryptStorage } from '../Utilities/common'
import { login } from '../Utilities/common'
import { useDispatch, useSelector } from 'react-redux'
import  { useGetUserInfoQuery } from '../redux/api/cloudflare'
import { setLoginInfo } from "../redux/slices/Auth"
import Profile from "./Profile"
import Login from "./Login"

/* eslint-disable jsx-a11y/anchor-is-valid */
const User = () => {
    const [searchParams] = useSearchParams()
    const info ={}
    const code = searchParams.get('code')
    const csrfCode = searchParams.get('state')
    const loginCSRF = encryptStorage.getItem('login_str')
    const dispatch = useDispatch()
    if(csrfCode!==loginCSRF){
        // means there is CSRF attack
        dispatch(setLoginInfo({
            username : "",
            isAuthenticated : false,
            profilePicURL : ""
          }))
    }

    const { data, isFetching, isSuccess, isError } = useGetUserInfoQuery({
       code, loginCSRF
    }, { skip : ((csrfCode!==loginCSRF)|| code === null) })

    if(isSuccess){
      dispatch(setLoginInfo({
        username : data.name,
        isAuthenticated : true,
        profilePicURL : data.picture
      }))
    }

    if(isError){
        dispatch(setLoginInfo({
            username : "",
            isAuthenticated : false,
            profilePicURL : ""
          }))
    }

    const username = useSelector(
        (state) => state.auth.username
      );
    
    const profilePicURL = useSelector(
        (state) => state.auth.profilePicURL
      );

    if(!code){
        // set the redux state
        if(!username){
          dispatch(setLoginInfo({
            username : 'Guest',
            isAuthenticated : true,
            profilePicURL : ''
          }))
        }
        return <Profile profilePicURL={'/something'} username={"Guest"}/>
      }
    return (<>
        { (isError || (csrfCode!==loginCSRF)) &&  <section className="bg-gray-900 body-font">
            <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
                <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                    <p className="text-5xl text-white text-center">
                       Sorry, We couldn't log you in. Try again! <br />  <br/><p>🐠🫧🪼🐟🐈</p>
                    </p>
                </div>
                <Login login={login}/>
            </div>
        </section>}
        
        { username && isSuccess && <Profile profilePicURL={profilePicURL} username={username}/>}
    </>)
}

export default User