import { createSlice } from '@reduxjs/toolkit'
import { encryptStorage } from '../../Utilities/common'

// create the initial state for the auth
const initAuthState = {
    authenticated : false,
    username : '',
    pathname : encryptStorage.getItem('pathname'),
    search :  encryptStorage.getItem('search')
}

const authSlice = createSlice({
    name : 'auth',
    initialState : initAuthState,
    reducers : {
        setLoginInfo : (state, action)=>{
            state.username = action.payload.username
            state.isAuthenticated = action.payload.isAuthenticated
            state.profilePicURL = action.payload.profilePicURL
        },
        setURLInfo : (state, action)=>{
            encryptStorage.setItem('pathname',action.payload.pathname)
            encryptStorage.setItem('search',action.payload.search)
            state.pathname = action.payload.pathname
            state.search = action.payload.search
        }
    }
})

// export the reducer
export default authSlice.reducer

// export the actions
export const { setLoginInfo, setURLInfo } = authSlice.actions