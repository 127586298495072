import { configureStore } from "@reduxjs/toolkit";
import authReducer  from './slices/Auth'
import indexReducer from './slices/index'
import timerReducer from './slices/Timer'
import { cloudflare } from "./api/cloudflare";

 export const store = configureStore({
    reducer : {
        auth : authReducer,
        index : indexReducer,
        timer : timerReducer,
        [cloudflare.reducerPath]: cloudflare.reducer,
    },
    middleware : ( getDefaultMiddlerware ) => getDefaultMiddlerware()
                                             .concat(cloudflare.middleware)
})