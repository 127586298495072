/* eslint-disable jsx-a11y/img-redundant-alt */
import { login } from '../Utilities/common'
import Login from './Login'
import { logUserEvent, AuditEventType } from '../Utilities/common'


/*
 The home component does the following
 1. Gives the login option
 2. Continue without logging In (option must be given because people are stopping here)
 3. Continue with login (For more features)
*/
const Home = () => {
     logUserEvent(AuditEventType.HomePageVisited,{})
     return (
        <section className="bg-gray-900 body-font min-h-screen">
            <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
                <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                    <h1 className="text-5xl text-gray-500">
                        We learned because <br /> somebody questioned us!
                    </h1>
                </div>
                <Login login={login}/>
            </div>
        </section>
    ) 
}

export default Home
// <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">