import { createSlice } from '@reduxjs/toolkit'

// create the initial state for the auth
const initIndexState = {
    content : []
}

const indexSlice = createSlice({
    name : 'index',
    initialState : initIndexState,
    reducers : {
        setIndex : (state, action)=>{
            state.content = action.payload.content
        }
    }
})

// export the reducer
export default indexSlice.reducer

// export the actions
export const { setIndex } = indexSlice.actions