/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { AuditEventType, logUserEvent } from "../Utilities/common"
const QuizIndex = () => {
    const index = useSelector(state => state.index.content)
    const username = useSelector(state => state.auth.username)

    useEffect(()=>{
        logUserEvent(AuditEventType.IndexPageVisited,{username})
    },[])
    return (
        index && <section className="text-gray-400 bg-gray-900 body-font">
            {/* <ADBanner height={50} width={320}/> */}
            <div className="container px-5 py-24 mx-auto">
                <div className="lg:w-2/3 w-full mx-auto overflow-auto">
                    <label className="text-yellow-500">coming soon - filter, pagination and search options</label>
                    <table className="table-auto w-full text-left whitespace-no-wrap">
                        <thead>
                            <tr>
                                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-800 border border-gray-300">Title</th>
                                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-800 border border-gray-300">Link (With Quiz ID)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                index.filter(i=>i.isPublic===true).map(q => <tr>
                                    <td className="px-4 py-3 border border-gray-300">{q.title}</td>
                                    <td className="px-4 py-3 text-lg text-white border border-gray-300"><Link to={`/startquiz?id=${q.id}`}>{q.id}</Link></td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
                {/* <div className="flex pl-4 mt-4 lg:w-2/3 w-full mx-auto">
                    <a className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </a>
                    <button className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">Button</button>
                </div> */}
            </div>
        </section>
    )
}

export default QuizIndex