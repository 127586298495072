/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { AuditEventType, logUserEvent } from "../Utilities/common"
import { encryptStorage } from "../Utilities/common"


const Profile = ({profilePicURL,username}) =>{
   // after the login check if there is a path name and search
    const pathname = useSelector(state=> state.auth.pathname)
    const search = useSelector(state => state.auth.search)
    logUserEvent(AuditEventType.ProfilePageVisited,{})
    if(username!=='Guest'){
        logUserEvent(AuditEventType.LoggedInAsLinkedInUser,{username})
    }
    const guestName = encryptStorage.getItem('guest_name')
    
    return(<section className="text-gray-400 bg-gray-900 body-font">
        {/* <ADBanner height={50} width={320}/> */}
        <div className="container px-5 py-24 mx-auto flex flex-col">
            <div className="lg:w-4/6 mx-auto min-h-screen">
                {/* <div className="rounded-lg h-64 overflow-hidden">
      <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1200x500"/>
    </div> */}
                <div className="flex flex-col sm:flex-row mt-10">
                    <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                        <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-800 text-gray-600">
                            <img src={profilePicURL} alt="" />
                        </div>
                        <div className="flex flex-col items-center text-center justify-center">
                            <h2 className="font-medium title-font mt-4 text-white text-lg">{ username==='Guest'? guestName : username }</h2>
                            {/* <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                        <p className="text-base text-gray-400"> Welcome</p> */}
                        </div>
                    </div>
                    <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        {/* <p className="leading-relaxed text-xl mb-4">We understand. You came here for one of these :
                        </p> <br /> */}
                        <p className="text-yellow-400 text-xl">We understand, you came here for one of these :</p> <br />
                       {pathname?.includes('startquiz') && search?.includes('?id') && <Link  
                            to={ pathname + search}
                            className="text-indigo-400 inline-flex items-center"> I want to answer the quiz that was shared
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </Link> } <br />
                        <Link to='/findquiz' className="text-indigo-400 inline-flex items-center">I want to find a quiz, I have quiz ID with me
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </Link> <br />
                        <Link to='/index' className="text-indigo-400 inline-flex items-center">I want to see all quizzes
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default Profile