import CryptoJS from 'crypto-js'
import { File } from 'megajs'
import { EncryptStorage } from 'encrypt-storage'
import Randomstring from 'randomstring'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";


export const encryptStorage = new EncryptStorage('@route-teachyou');

export const loadScript = (src) => {
  return new Promise(function (resolve, reject) {
    var s;
    s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

export const login = () => {
  const random = Randomstring.generate()
  encryptStorage.setItem('login_str', random)
  if (window.location.host.includes('localhost')) {
    window.location.assign(`/auth/linkedin/callback?code=AQQKn5Qk3GUwg4HBJwaZuGdjG49TLsU731doYKHT0k4V9cvvZTzb34g7XUbQXKspRb-fsIIHIeFxK4IEjgIc5DPA3dTVQQo6m2BHFkOq8ar02iSoomG7Woe_nglZaWoXNZZriA61sEb9lfZoInplEWHRUaCRiZWyxghVPcCAEUxXqq5tq-d7TJZTuATwioNJP40fTf4y-47DS_DlPdk&state=${random}`)
  } else {
    window.location.assign(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=770bbca8hvcgzn&redirect_uri=https://teachyou.co.in/auth/linkedin/callback&state=${random}&scope=openid%20profile`)
  }
}

export const downloadQuizContentFromFile = fileID => {
  return new Promise((resolve, reject) => {
    const file = File.fromURL(`https://mega.nz/file/${fileID}`)
    file.loadAttributes(error => {
      if (error) return console.error(error)
      // Then finally download the file like usual
      file.download((error, data) => {
        if (error) {
          reject(error.message)
        }
        resolve(JSON.parse(data))
      })
    })
  })
}


export const downloadIndex = () => {
  return new Promise((resolve, reject) => {
    // Get the folder object from the URL
    const folder = File.fromURL('https://mega.nz/folder/eQ8D2JaS#EOtly3BLYdddORZgdx9sUg')
    // Load folder attributes
    folder.loadAttributes(error => {
      if (error) return console.error(error)

      // Download a file from the folder
      folder.children
        .find(file => file.name === 'index.json')
        .download((error, data) => {
          if (error) {
            reject(error.message)
          }
          const indexData = JSON.parse(data)
          resolve(indexData)
        })
    })
  })
}


export const downloadCurrentQuizContent = () => {
  return new Promise((resolve, reject) => {
    window.selectedQuiz.download((error, data) => {
      if (error) {
        reject(error.message)
      }
      const quizContent = JSON.parse(data)
      resolve(quizContent)
    })
  })
}


export const getPublicQuizzes = () => {
  return new Promise((resolve, reject) => {
    // Get the folder object from the URL
    const folder = File.fromURL('https://mega.nz/folder/SB9FgTzQ#ufSn_-Bdm9i-BIF0Gte22Q')
    // Load folder attributes
    folder.loadAttributes(error => {
      if (error) return console.error(error)

      // Download a file from the folder
      const childQuizzes= folder?.children || []
      window.quizzes = childQuizzes
      resolve(childQuizzes)
    })
  })
}


const CryptoJSAesJson = {
  stringify: function (cipherParams) {
    var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse: function (jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
    return cipherParams;
  }
}
export const encrypt = content => CryptoJS.AES.encrypt(JSON.stringify(content), './path', { format: CryptoJSAesJson }).toString()
export const decrypt = content => CryptoJS.AES.decrypt(content, './path', { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8);


export const createEncryptedParams = (objData) => {
  return btoa(encrypt(objData))
}

export const createDecryptedParams = (encData) => {
  return JSON.parse(decrypt(atob(encData)))
}


export function download(file, text) {
  var element = document.createElement('a');
  element.setAttribute('href',
    'data:text/plain;charset=utf-8, '
    + encodeURIComponent(text));
  element.setAttribute('download', file);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function getFirebaseConfig() {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
  }
}


const firebaseConfig = getFirebaseConfig()
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);
export const logUserEvent = (eventType,eventData) => {
  logEvent(analytics,eventType, eventData)
}


export const AuditEventType = {
  "HomePageVisited":"HomePageVisited", // 1
  "LoggedInAsGuest":"LoggedInAsGuest", // 2
  "LoggedInAsLinkedInUser":"LoggedInAsLinkedInUser", // 2
  "ProfilePageVisited":"ProfilePageVisited", // 2
  "FindQuizPageVisited":"FindQuizPageVisited", // 3
  "QuizSearched":"QuizSearched", // 3
  "QuizNotFound":"QuizNotFound", // 3
  "QuizLoaded":"QuizLoaded",// 3
  "MovedOutOfQuiz":"MovedOutOfQuiz", // 3 --> may be to find answers {To be handled}
  "QuizCompleted":"QuizCompleted",// 4
  "QuizShared":"QuizShared", // 5
  "ResultsPageVisited":"ResultsPageVisited",// 4
  "IndexPageVisited":"IndexPageVisited",// 4
  "AboutToPostQuizLinkOnLinkedIn":"AboutToPostQuizLinkOnLinkedIn",// 5
}