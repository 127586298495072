/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { encryptStorage } from "../Utilities/common"
import { useDispatch } from "react-redux"
import { issueCommand } from "../redux/slices/Timer"

const Timer = ({timeLimitInMinutes, quizID}) =>{
    const key = `${quizID}ttl`
    const storedTime = encryptStorage.getItem(key) || timeLimitInMinutes
    const dispatch = useDispatch()
    const updatedValue = storedTime < 0 ? 0 : storedTime
    const [minsLeft, setMinsLeft] = useState(updatedValue)

    // save the value to the ls
    useEffect(()=>{
        encryptStorage.setItem(key,minsLeft)
        if(minsLeft===0){
            encryptStorage.removeItem(key)
            dispatch(issueCommand({command:'TimesUp'}))
        }
    }, [minsLeft])

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(minsLeft>0){
                const timeLeft = encryptStorage.getItem(key) 
                const newTimeLeft = timeLeft -1
                if(newTimeLeft===0){
                    clearInterval(interval)
                    setMinsLeft(newTimeLeft)
                    return
                }
                setMinsLeft(newTimeLeft)
            }
         },60000)
    },[])
    return (<label className="fixed inset-x-0 bottom-0 bg-gray-900 text-red-500 text-3xl p-3 border-dashed border-2 border-red-500 "> {minsLeft} mins left</label>)
}

export default Timer