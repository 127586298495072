/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setLoginInfo } from "../redux/slices/Auth"
import { setIndex } from "../redux/slices" 
import { downloadIndex } from "../Utilities/common"


const Menu = () => {
    // show the menu options if the user is authenticated
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const index = useSelector(state => state.index.content) || []
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toggle = () => {
        // Mobile menu toggle
        const mobileMenu = document.querySelector(".navigation-menu")
        mobileMenu.classList.toggle("hidden")
    }

    const logOut = () => {
        dispatch(setLoginInfo({
            username: "",
            isAuthenticated: false,
            profilePicURL: ""
        }))
        // then navigate the user to login
        navigate('/')
    }

    const showMenu = (event) => {
        // Find the next sibling element which is the dropdown menu
        const dropdownMenu = event.target.nextElementSibling
        // Toggle the 'hidden' class to show or hide the dropdown menu
        if (dropdownMenu.classList.contains("hidden")) {
            // Hide any open dropdown menus before showing the new one
            document.querySelectorAll(".dropdown-menu").forEach((menu) => {
                menu.classList.add("hidden")
            })
            dropdownMenu.classList.remove("hidden")
        } else {
            dropdownMenu.classList.add("hidden")
        }
    }
    useEffect(() => {
        // Optional: Clicking outside of an open dropdown menu closes it
        window.addEventListener("click", (event) => {
            if (!event.target.matches(".dropdown-toggle")) {
                document.querySelectorAll(".dropdown-menu").forEach((menu) => {
                    if (!menu.contains(event.target)) {
                        menu.classList.add("hidden")
                    }
                })
            }
        })
        const body = document.body
        document.querySelectorAll('nav a').forEach(a => a.onclick = () => {
            body.click()
            document.querySelector('button.mobile-menu-button').click()
        })
    })

    useEffect(()=>{
        const getIndexContent = async () => {
            // load the index only if authenticated
            if (index?.length === 0 && isAuthenticated) {
                const indexContent = await downloadIndex()
                dispatch(setIndex({
                    content : indexContent
                }))
            }
        }

        getIndexContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ isAuthenticated, index.length])
    return (
        <nav className="sticky bg-gray-800 text-white">
            <div className="container mx-auto px-4 md:flex items-center gap-6">
                {/* Logo */}
                <div className="flex items-center justify-between md:w-auto w-full">
                    <Link to="/" className="flex items-center py-5 px-2 text-white flex-1">
                        <span className="font-bold dancing-menu-item">Teach You</span>
                    </Link>
                    {/* Mobile Menu Button  */}
                    <div className="md:hidden flex items-center">
                        <button className="mobile-menu-button" onClick={toggle}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <title>bars-3-bottom-left</title>
                                <g fill="none">
                                    <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                {/* Primary Navigation  */}
                <div className="hidden md:flex md:flex-row flex-col items-center justify-start md:space-x-1 navigation-menu pb-3 md:pb-0 navigation-menu">
                    {/* <Link to="/" className="py-2 px-3 block">Home</Link> */}


                    {isAuthenticated && <>
                        {/* <div className="relative">
                            <button onClick={showMenu} className="dropdown-toggle py-2 px-3 hover:bg-gray-700 flex items-center gap-2 rounded">
                                <span className="pointer-events-none">Services</span>
                                <svg className="w-3 h-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <title>chevron-down</title>
                                    <g fill="none">
                                        <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </g>
                                </svg>
                            </button>
                             <div className="dropdown-menu absolute hidden bg-gray-700 text-white rounded-b-lg pb-2 w-48 z-40">
                                <Link to="support" className="block px-6 py-2 hover:bg-gray-800">FrontEnd Support</Link>
                                <Link to="interview" className="block px-6 py-2 hover:bg-gray-800">Interview support</Link>
                                <Link to="seo" className="block px-6 py-2 hover:bg-gray-800">SEO</Link>
                            </div> 
                        </div> */}
                        <div className="relative">
                            <button onClick={showMenu} className="dropdown-toggle py-2 px-3 hover:bg-gray-700 flex items-center gap-2 rounded">
                                <span className="pointer-events-none">Quzzies</span>
                                <svg className="w-3 h-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <title>chevron-down</title>
                                    <g fill="none">
                                        <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </g>
                                </svg>
                            </button>
                            <div className="dropdown-menu absolute hidden bg-gray-700 text-white rounded-b-lg pb-2 w-48 z-40">
                                <Link to="index" className="block px-6 py-2 hover:bg-gray-800"> All Quizzes</Link>
                                <Link to="findquiz" className="block px-6 py-2 hover:bg-gray-800"> Find Quiz</Link>
                                {/* <Link to="morequiz" className="block px-6 py-2 hover:bg-gray-800"> More</Link> */}
                            </div>
                        </div>
                    </>}



                    {/* <Link to="contact" className="py-2 px-3 block">Contact</Link> */}
                    {isAuthenticated && <button to="logout" onClick={logOut} className="py-2 px-3 block text-indigo-400">Log out</button>}
                </div>
            </div>
        </nav>

    )
}

export default Menu