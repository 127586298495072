/* eslint-disable jsx-a11y/anchor-is-valid */
import './QuizWizard.css'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { logUserEvent, AuditEventType, getPublicQuizzes } from '../Utilities/common'
import Modal from './Modal'
import { Link } from 'react-router-dom'


const QuizFinder = () => {
    const username = useSelector(state => state.auth.username)
    const [quizzes, setQuizzes] = useState([])
    // use the setIsModalOpen to open or close the modal
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [quizInfo, setQuizInfo] = useState()
    
    const QuizIdForm = useRef()
    useEffect(() => {
            logUserEvent(AuditEventType.FindQuizPageVisited,{})
            const getPublicQuizzesDetails = async () => {
                // get the index from the ls
                let childQuizzes = await getPublicQuizzes()
                // if the index is valid set the state
                if (childQuizzes.length !== 0) {
                    setQuizzes(childQuizzes)
                }
            }
            getPublicQuizzesDetails()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // reset the form 
    // const clearQuizForm = (event) => {
    //     event.preventDefault()
    //     QuizIdForm.current.reset()
    //     setQuizInfo()
    // }

    // proceed with the quiz
    const searchQuiz = (event) => {
        event.preventDefault()
        // get the quizId from the form
        const quizIDFromForm = QuizIdForm.current[0].value.trim()?.toLowerCase()
        // user is trying to find quiz
        logUserEvent(AuditEventType.QuizSearched,{quizID : quizIDFromForm})
        // find the quiz from the state if exists
        const quizFound = quizzes.find(i => i.name===`${quizIDFromForm}.json`)
        if (!quizFound) {
            // open the modal
            logUserEvent(AuditEventType.QuizNotFound,{quizID : quizIDFromForm})
            setIsModalOpen(true)
        } else {
            // set the window 
            window.selectedQuiz = quizFound
            setQuizInfo({ ...quizFound, 
                quizLink: `/startquiz?id=${quizFound.name.split('.')[0]}` })
            // scroll to the bottom of the page
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })    
        }
    }

    const shareQuiz = () =>{
        logUserEvent(AuditEventType.QuizShared, {quizID : quizInfo.quizLink, sharedBy : username })
        navigator.clipboard.writeText(`${window.location.host}${quizInfo.quizLink}`);
        alert("Quiz link is copied successfully!")
    }

    return (
        <>
            {quizzes.length === 0 && <div className="text-gray-100 bg-gray-900 body-font  md:col-span-3">
                <div className="mx-10 py-10">
                    <div className="flex gap-2 justify-center">
                        <div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
                        <div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
                        <div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
                    </div>
                    <div className='text-yellow-400 text-5xl text-center'>
                        Setting up the quiz finder
                    </div>
                </div>
            </div>}

            {
                quizzes.length > 0 && <section className="text-gray-400 body-font bg-gray-900">
                   {/* <ADBanner height={50} width={320}/> */}
                    <div className="container px-5 py-24 mx-auto">                  
                        <div className="flex flex-col text-center w-full mb-20">
                            <form ref={QuizIdForm}>
                                <div className="mx-10 py-10">
                                    <p className="text-yellow-400 text-2xl" name="quizID">Enter the Quiz ID</p>
                                    <br />
                                    <input type="text" placeholder="Type the quiz ID here" id="quizID" className="my-4 text-black-900 text-2xl w-full px-4 rounded" style={{ "height": "50px" }} /> <br />
                                    <div className='flow-root'>
                                    <button className='bg-blue-900 px-10 py-5 rounded float-right text-white' onClick={searchQuiz}> search </button>
                                    {/* <button className='bg-blue-900 px-10 py-5 rounded float-left text-white' onClick={clearQuizForm} > Clear </button> */}
                                    </div>
                                    
                                </div>
                            </form>
                            <br />
                            <h2 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Instructions</h2>
                            <p className="lg:w-2/3 mx-auto leading-relaxed text-base"> I hope you have the quiz ID handy.  Enter the quiz ID in the text box and hit the search button.  You can then do many things from here. Look at the "Share the quiz link" and "Take up this quiz" sections below.</p>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
                                <h2 className="text-lg sm:text-xl text-white font-medium title-font mb-2">Share the quiz link</h2>
                                <p className="leading-relaxed text-base mb-4"> Use this option if you want to create a direct quiz link that can be shared</p>
                                {quizInfo?.quizLink && <a className="animate-pulse text-yellow-400 inline-flex items-center" onClick={shareQuiz}> Share {quizInfo.name.split('.')[0]} quiz 
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>}
                            </div>
                            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
                                <h2 className="text-lg sm:text-xl text-white font-medium title-font mb-2">Take up this quiz</h2>
                                <p className="leading-relaxed text-base mb-4"> Use this option if you want to take up this quiz right away</p>
                                {quizInfo?.quizLink && <Link className="animate-pulse text-yellow-400 inline-flex items-center" to={quizInfo.quizLink}> Take {quizInfo.name.split('.')[0]} quiz
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </Link>}
                            </div>
                            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
                                <h2 className="text-lg sm:text-xl text-white font-medium title-font mb-2">I want to create a quiz like this</h2>
                                <p className="leading-relaxed text-base mb-4"> Use this option if you want to create a quiz</p>
                                <a className="text-indigo-400 inline-flex items-center">Learn More (coming soon)
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
                                <h2 className="text-lg sm:text-xl text-white font-medium title-font mb-2"> Don't know the quiz ID?</h2>
                                <p className="leading-relaxed text-base mb-4"> Use this option if you don't know the quiz ID.</p>
                                <Link to='/index' className="text-indigo-400 inline-flex items-center">Learn More
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                       
                    </div>
                    <Modal title={"Quiz not found"}
                        type={"error"}
                        acceptButtonText={"Ok"}
                        visible={isModalOpen}
                        body={"We do not have any quizzes with this quiz ID. Please enter the correct quiz ID and try again. If you feel the quiz ID you entered is correct, retry once more!"}
                        mainAction={() => { localStorage.removeItem('meta_i'); setIsModalOpen(false) }}
                        cancelAction={() => { localStorage.removeItem('meta_i'); setIsModalOpen(false) }} />
                </section>
            }
        </>

    )
}

export default QuizFinder
