import './App.css'
import Menu from './components/Menu';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import CreateQuiz from './components/CreateQuiz';
import QuizFinder from './components/QuizFinder'
import QuizWizard from './components/QuizWizard';
import Results from './components/Results';
import User from './components/User';
import WithAuth from './components/WithAuth';
import QuizIndex from './components/QuizIndex';
import ShowScores from './components/ShowScores';

function App() {
  return (
    <>
      {/* the below div stretches to full width in 3 devices */}
      <div className="bg-gray-100 sm:col-span-2 md:col-span-3 lg:col-span-4"><Menu /></div>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/createquiz" element={<CreateQuiz/>}/>
        <Route path="/findquiz" element={ <WithAuth>
          <QuizFinder/>
        </WithAuth>} />
        <Route path="/startquiz" element={ <WithAuth> <QuizWizard/> </WithAuth>} />
        <Route path="/index" element={ <QuizIndex/>} />
        <Route path="/results" element={ <Results/>} />
        <Route path="/op" element={ <ShowScores/>} />
        <Route path="/auth/linkedin/callback" element={ <User/>} />
      </Routes>
    </>
  );
}

export default App;
