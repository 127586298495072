// this component will check if the user is authenticated,
// save the incoming URL in the state
// once authenticated, will take you to the right destination page
import { useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setURLInfo } from "../redux/slices/Auth"
import Home from "./Home"


const WithAuth = ({ children }) => {
    const dispatch = useDispatch()
    const { pathname, search} = useLocation()
    // Get the login status
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    // Get the pathname and search
    const pathnameFromStore = useSelector(state=> state.auth.pathname)
    const searchFromStore = useSelector(state=> state.auth.search)
    // save the pathname and search to the redux
    if(pathname!==pathnameFromStore || search!== searchFromStore){
        // update the redux store
        dispatch(setURLInfo({
           pathname, search
          }))
    }
    return isAuthenticated? children : <Home/>
}

export default WithAuth