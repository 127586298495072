import { createSlice } from '@reduxjs/toolkit'

// create the initial state for the auth
const initTimerState = {
    command : 'In-Progress', // TimeUp
}

const timerSlice = createSlice({
    name : 'timer',
    initialState : initTimerState,
    reducers : {
        issueCommand : (state, action)=>{
            state.command = action.payload.command
        }
    }
})

// export the reducer
export default timerSlice.reducer

// export the actions
export const { issueCommand } = timerSlice.actions