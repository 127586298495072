/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { createDecryptedParams, encryptStorage } from "../Utilities/common"
import { useSelector } from "react-redux"
import { logUserEvent, AuditEventType } from "../Utilities/common"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { issueCommand } from "../redux/slices/Timer"
import { usePostScoresMutation } from "../redux/api/cloudflare"

const Results = () =>{
    const [ postScores ] = usePostScoresMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const urlData= searchParams.get('d')
    const { quizTitle, score, date, username, quizID, createdBy, creatorsProfile, next} = createDecryptedParams(`${urlData}`)
   
    // check if the user is logged in
   let loggedInUser = useSelector(state => state.auth.username)
   if(loggedInUser==='Guest'){
    loggedInUser = username
   }
   
   useEffect(()=>{
    postScores({
      username : loggedInUser,
      score : score,
      date : date,
      quizid : quizID
    })
    logUserEvent(AuditEventType.ResultsPageVisited,{username : loggedInUser})
   },[])
   
   // copy to clipboard
   const copyToClipboard = () =>{ navigator.clipboard
                                          .writeText(
                  `I took a quiz on ${quizTitle}. 

Here's the link: https://teachyou.co.in/startquiz?id=${quizID} 

It is a quick and free way to test your knowledge.`)
alert('Copied to clipboard! On LinkedIn, create a new post and simply paste it!')
logUserEvent(AuditEventType.AboutToPostQuizLinkOnLinkedIn,{quizID})
// take the user to linkedIn
window.open('https://www.linkedin.com/', '_blank')

                                          }
    useEffect(()=>{
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        encryptStorage.removeItem(`${quizID}ttl`)
        dispatch(issueCommand({command:'Ended'}))
    },[])
    return (<div class="bg-gray-900 body-font">
      {/* <ADBanner height={50} width={320}/> */}
         <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
            <p className="text-3xl text-yellow-500">Congratulations!</p>
            <p className="text-5xl text-slate-500 text-center mt-5">{username} scored {score}%</p>
            <p className="text-3xl text-blue-500 text-center mt-10">{quizTitle} Quiz</p>
            <p className="text-xl text-blue-100 text-center mt-1 italic"> - taken on {date} </p>
            <p className="text-xl text-yellow-500 text-center mt-1"> - quiz created by <a className="" target="_blank" href={creatorsProfile} rel="noreferrer">{createdBy} [ check who is this❓]</a> </p>

            { loggedInUser===username && next &&
           <p className="text-3xl text-center mt-10 border-solid border-2 border-sky-500"> 
                <p className="text-xl text-white">
                  👑 You unlocked another quiz 👑 <br/>
                  {next}  <br/> 
                </p>
              <button onClick={e=>navigate(`/startquiz?id=${next}`)} className="bg-gray-300 p-5 mt-4"> Yes, let me take it up </button>
            </p> }

            { loggedInUser===username && !next &&
           <p className="text-3xl text-center mt-10 border-solid border-2 border-sky-500"> 
                <p className="text-xl text-white">
                  👑 More Quizzes 👑 <br/>
                </p>
              <button onClick={e=>navigate(`/index`)} className="bg-gray-300 p-5 mt-4"> Yes, show me! </button>
            </p> }
            
           { loggedInUser===username && 
           <p className="text-3xl text-center mt-10 border-solid border-2 border-sky-500"> 
                <p className="text-xl text-white">
                  I took a quiz on {quizTitle}, <br/>
                  Here's the link: https://teachyou.co.in/startquiz?id={quizID}  <br/> 
                  It is a quick and free way to test your knowledge.
                </p>
              <button onClick={copyToClipboard} className="bg-gray-300 p-5 mt-4"> Post it on LinkedIn (Copies to clipboard) </button>
            </p> }
         </div>
    </div>)
}

export default Results
