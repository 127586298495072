/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react"
import { useGetScoresMutation } from "../redux/api/cloudflare"
import { ExportJsonCsv } from "react-export-json-csv"
const ShowScores = () => {
    const [getScores, { isSuccess, data}] = useGetScoresMutation()
    const today = new Date().toLocaleDateString()
    const [fileTitle, setFileTitle] = useState()
    const getResultsForQuizID = () => {
        if(!isSuccess){
            const searchQuizID = document.getElementById('quizid').value.trim()
            setFileTitle(`results_${searchQuizID}_${today}.csv`)
            getScores({ quizid: searchQuizID })
            return
        }
        const downloadButton = document.getElementsByTagName('button')[1]
                downloadButton.click()

    }
    const [exportData, setExportData]=useState([])
    const headers = [{ key: "username", name: 'Name' },
    { key: "quizid", name: "Quiz ID" }, { key: "score", name: "Score" }, { key: "date", name: "Date" }]
    if (isSuccess && exportData.length===0) {
        setExportData(data.documents)
    }

    return (
        <div class="bg-gray-900 body-font">
            <div class="container px-5 mx-auto min-h-screen">
                <div class="flex flex-col text-center w-full mb-12">
                    <h2 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Results</h2>
                </div>
                <div class="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:px-0 items-end sm:space-x-4 sm:space-y-0 space-y-4">
                    <div class="relative sm:mb-0 flex-grow w-full">
                        <label for="quizid" class="leading-7 text-sm text-gray-400">Enter the Quiz ID</label>
                        <input type="quizid" id="quizid" name="quizid" class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 focus:bg-transparent text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <ExportJsonCsv id="btnExport" class="hidden" headers={headers} items={exportData} fileTitle={fileTitle}>.</ExportJsonCsv>
                    <button onClick={getResultsForQuizID} class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">{isSuccess?'Download':'Get'}</button>
                </div>
            </div>
        </div>)
}

export default ShowScores